import React, { useState } from "react";
import "./Popup.css";
import CustomJobseekerSignUp from "./CustomJobseeker";
import CustomEmployerSignup from "./CustomEmployer";

const Popup = ({ openpopup, setopenpopup, user, loginType }) => {
  console.log(openpopup,'****************')
  if (openpopup) {
    return (
      <div className="custom-modal-overlay">
        <div className="custom-modal">
          <div className="custom-modal-content">
            {user == "Jobseeker" ? (
              <CustomJobseekerSignUp user={user} loginType={loginType} />
            ) : (
              <CustomEmployerSignup user={user} loginType={loginType} />
            )}
            <span
              className="custom-modal-close"
              onClick={() => {
                setopenpopup(false);
              }}
            >
              &times;
            </span>
          </div>
        </div>
      </div>
    );
  }
};

export default Popup;

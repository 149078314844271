import { Box, Button, Grid, Dialog, Stack, Typography } from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import React from "react";
import { useState } from "react";
import LanguageLevel from "../../CommonCode/LanguageLevel";
import AllLanguage from "../../CommonCode/AllLanguage";
import { axiosAPI } from "../../Axios/Axios";
import { errorNotify, succesNotify } from "../../CommonCode/Commonfunc";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import LanguageLevelJobSeeker from "../../CommonCode/LanguageLevelJobseeker";
import AllLanguageJobSeeker from "../../CommonCode/AllLanguagesjobseeker";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: {
    xs: "60%",
    sm: "50%",
    md: "40%",
    lg: "30%",
  },
  bgcolor: "#FFFFFF",
  boxShadow: 24,
  p: 4,
  borderRadius: "8px",
};

export default function LanguageEditModal({ open, handleClose, languageData }) {
  //// console.log(languageData);
  const [language, setLanguage] = useState("");
  const [languageReadLevel, setLanguageReadLevel] = useState("");
  const [isdisabled,setisdisabled]= useState(false);
  
  const [languageWriteLevel, setLanguageWriteLevel] = useState("");
  const setLanguageLevel = () => {
    if (Object.keys(languageData).length !== 0) {
      setLanguage(languageData.languageid);
      setLanguageReadLevel(languageData.spokenid);
      setLanguageWriteLevel(languageData.writtenid);
    }
  };

  const editLanguage = async () => {
    if (!language) {
      return errorNotify(Labels.languagefieldisrequird);
    } else if (!languageReadLevel) {
      return errorNotify(Labels.Spokenlevelfieldisrequired);
    } else if (!languageWriteLevel) {
      return errorNotify(Labels.Writtenlevelfieldisrequired);
    }
    const postData = {
      id: languageData?.id,
      language: language,
      written: languageWriteLevel,
      spoken: languageReadLevel,
      // language: localStorage.getItem("language"),
    };  
    setisdisabled(true)
    const result = await axiosAPI.post("/updateLanguageLevel", postData);
    const { status, message } = result.data;
    if (status) {
      succesNotify(message);
      handleClose();
      setLanguage("");
      setLanguageReadLevel("");
      setLanguageWriteLevel("");
    } else {
      errorNotify(message);
    }
  };

  useEffect(() => {
    setLanguageLevel();
  }, []);
  const Labels = useSelector((state) => state.allLabels.labels);
  return (
    <Dialog open={open} onClose={() => handleClose(false)}>
      <Box sx={{ p: 2.5 }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ my: 2 }}
        >
          <Typography variant="h6" sx={{ color: "#106995" }}>
          {Labels?.language}
          </Typography>
          <HighlightOffIcon
            sx={{
              color: "#106995",
            }}
            onClick={() => handleClose(true)}
          />
        </Stack>
        <Box sx={{ my: 1 }}>
          <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
            <Box sx={{ width: 200 }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
              {Labels?.Spoken}
              </Typography>
              <AllLanguageJobSeeker
                value={language}
                setValue={setLanguage}
                label="Languages"
              />
            </Box>
            <Box sx={{ width: 200 }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                {Labels.Read}
              </Typography>
              <LanguageLevelJobSeeker
                value={languageReadLevel}
                setValue={setLanguageReadLevel}
                label={Labels.Spoken}
              />
            </Box>
            <Box sx={{ width: 200 }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
              {Labels?.Written}
              </Typography>
              <LanguageLevelJobSeeker
                value={languageWriteLevel}
                setValue={setLanguageWriteLevel}
                label={Labels?.Written}
              />
            </Box>
          </Stack>
        </Box>
        <Stack direction="row" justifyContent="center" sx={{ p: 1.5 }}>
          <Button
            variant="solid"
            sx={{
              background: "#106995",
              borderRadius: 3,
              color: "#fff",
              "&:hover": {
                backgroundColor: "#106995",
              },
              "&:active": {
                backgroundColor: "#106995",
              },
              "&:disabled": { color:"white",
                backgroundColor: "#106995",
              },
            }}
            onClick={editLanguage}
          >
            {Labels?.Save}
          </Button>
        </Stack>
      </Box>
    </Dialog>
  );
}

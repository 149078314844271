import {
  Box,
  Button,
  Stack,
  Divider,
  Typography,
  ButtonBase,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import { useSelector } from "react-redux";
import { Fragment, useState } from "react";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import React from "react";
import NaranjoLogo from "../../Images/logo1 (1).png";
import fifyPluslogo from "../../Images/fifyPluslogo.png";
import { axiosAPI } from "../Axios/Axios";
import { ImFacebook } from "react-icons/im";
import {
  AiOutlineYoutube,
  AiOutlineTwitter,
  AiOutlineInstagram,
} from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import "./Style.css";

const Footer = () => {
  const allList = useSelector((state) => state.allList.list);
  const navigate = useNavigate();
  const gotoLandingPage = (e) => {
    e.preventDefault();
    const searchData = {
      language: "en",
      search: "",
    };
    navigate("/jobSearchPrelogin", { state: searchData });
  };
  const year = new Date().getFullYear();
  const postData = {
    language: "en",
    search: "",
    skills: "",
    city: "",
    category: "",
    type: "",
  };
  // -----------------Search Jobs----------------------
  const [formdata, setformData] = useState({
    PrivacyPolicy: "",
  });
  const { PrivacyPolicy } = formdata;
  const [showprivacy, setShowprivacy] = useState(false);
  // const [open, setOpen] = useState(false);
  const [showterms, setShowterms] = useState(false);
  const handleOpenterms = () => {
    setShowterms(true);
  };
  const handleCloseterms = () => {
    setShowterms(false);
  };

  const handleOpenDialog = () => {
    setShowprivacy(true);
  };

  const handleCloseDialog = () => {
    setShowprivacy(false);
  };
  const Labels = useSelector((state) => state.allLabels.labels);
  return (
    <Stack sx={{ background: "#005F8E", padding: { sm: 0, md: 3 } }}>
      {/* <Box
        sx={{
          width: { sm: "100%", md: "90%" },
          marginTop: { sm: 10, md: -20 },
          padding: 5,
          display: { sx: "block", md: "flex" },
          justifyContent: "space-between",
          alignItems: "center",
          background: "#1C614E",
          borderRadius: 4,
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <div>
          <Typography
            component="h4"
            variant="h4"
            color="inherit"
            sx={{ color: "#fff" }}
          >
            Never want to miss
          </Typography>
          <Stack direction="row" sx={{ marginBottom: 2 }}>
            <Typography
              component="h4"
              variant="h4"
              color="inherit"
              sx={{ color: "#fff" }}
            >
              any&nbsp;
            </Typography>
            <Typography
              component="h4"
              variant="h4"
              color="inherit"
              sx={{ color: "#BA8C04" }}
            >
              job news
            </Typography>
          </Stack>
        </div>
        <div className="subscribe-input">
          <input placeholder="Enter your Email" type="email" />
          <button>Subscribe</button>
        </div>
      </Box> */}

      <div
        className="flex-container"
        style={{
          margin: "35px 0",
          display: "flex",
         
        }}
      >
        <div
          style={{
            backgroundColor: "white", // White background color
            padding: "10px", // Add some padding to create space around the logo
            borderRadius: "50%", // Make the background circular
          }}
        >
          <img
            src={NaranjoLogo}
            alt={"logo"}
            style={{ width: "80px", height: "70px" }}
          />
        </div>

        <div className="flex">
          <Stack
            direction={{ xs: "column", md: "row" }}
            sx={{ color: "#fff", display: "flex", mt: 4 }}
          >
            <Button
              variant="text"
              sx={{
                textTransform: "capitalize",
                color: "#fff",
                // boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                // background: "#1C614E1C",
                margin: 1,
              }}
              onClick={() =>
                navigate("/jobSearchPrelogin", { state: postData })
              }
            >
              {Labels?.Jobs}
            </Button>
            <Button
              variant="text"
              sx={{
                textTransform: "capitalize",
                color: "#fff",
                // boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                // background: "#1C614E1C",
                margin: 1,
              }}
              onClick={() => navigate("/about")}
            >
              {Labels?.AboutUs}
            </Button>
            <Button
              variant="text"
              sx={{
                textTransform: "capitalize",
                color: "#fff",
                // boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                // background: "#1C614E1C",
                margin: 1,
              }}
              onClick={() => navigate("/contact")}
            >
              {Labels?.Contactus}
            </Button>
            <Button
              variant="text"
              sx={{
                textTransform: "capitalize",
                color: "#fff",
                // boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                // background: "#1C614E1C",
                margin: 1,
              }}
              onClick={() => {
                handleOpenterms();
              }}
            >
              {Labels?.TandC}
            </Button>

            <Dialog open={showterms} onClose={handleCloseterms}>
              <Stack
                direction="row"
                justifyContent="space-between"
                sx={{ p: 0.5, mt: 0.5 }}
              >
                <DialogTitle
                  sx={{
                    color: "#005F8E",
                  }}
                >
                  {Labels?.TermsandConditions}
                </DialogTitle>
                <HighlightOffIcon
                  sx={{
                    color: "#005F8E",
                    cursor: "pointer",
                    m: 2,
                  }}
                  onClick={handleCloseterms}
                />
              </Stack>
              <DialogContent sx={{ pt: 0, mb: 1 }}>
                {allList?.privacyPolicy?.terms && (
                  <Typography>{allList?.privacyPolicy?.terms}</Typography>
                )}
              </DialogContent>
            </Dialog>

            <Button
              variant="text"
              sx={{
                textTransform: "capitalize",
                color: "#fff",
                // boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                // background: "#1C614E1C",
                margin: 1,
              }}
              onClick={() => {
                handleOpenDialog();
              }}
            >
              {Labels?.PrivacyPolicy}
            </Button>
            <Stack>
              <Dialog open={showprivacy} onClose={handleCloseDialog}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  sx={{ p: 0.5, mt: 0.5 }}
                >
                  <DialogTitle
                    sx={{
                      color: "#005F8E",
                    }}
                  >
                    Privacy Policy
                  </DialogTitle>
                  <HighlightOffIcon
                    sx={{
                      color: "#005F8E",
                      cursor: "pointer",
                      m: 2,
                    }}
                    onClick={handleCloseDialog}
                  />
                </Stack>

                <DialogContent sx={{ pt: 0, mb: 1 }}>
                  {allList?.privacyPolicy?.privacy && (
                    <Typography>{allList?.privacyPolicy?.privacy}</Typography>
                  )}
                </DialogContent>
              </Dialog>
            </Stack>
          </Stack>
        </div>
      </div>

      <Stack sx={{ borderTop: "1px solid rgba(0, 0, 0, 0.07)" }}>
        <div className="flex">
          <p style={{ color: "#fff" }}>
            {Labels?.AllRightsReserved} &copy; {year} &nbsp;
          </p>
          <p style={{ color: "#fff" }}>{Labels?.DevelopedbyM2india}</p>
          <Stack
            direction="row"
            sx={{
              color: "#fff",
              marginBottom: "35px",
              justifyContent: "center",
              mt: 2,
            }}
          >
            <div
              style={{
                background: "#fff",
                fontSize: 20,
                width: 30,
                height: 30,
                borderRadius: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 10,
                margin: 5,
                color: "#000",
              }}
            >
              <ImFacebook />
            </div>
            <div
              style={{
                background: "#fff",
                fontSize: 20,
                width: 30,
                height: 30,
                borderRadius: "100%",
                display: "flex",
                margin: 5,
                justifyContent: "center",
                alignItems: "center",
                marginTop: 5,
                color: "#000",
              }}
            >
              <AiOutlineYoutube />
            </div>
            <div
              style={{
                background: "#fff",
                fontSize: 20,
                width: 30,
                height: 30,
                borderRadius: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: 5,
                color: "#000",
              }}
            >
              <AiOutlineTwitter />
            </div>
            <div
              style={{
                background: "#fff",
                fontSize: 20,
                width: 30,
                height: 30,
                borderRadius: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: 5,
                color: "#000",
              }}
            >
              <AiOutlineInstagram />
            </div>
          </Stack>
        </div>
      </Stack>
    </Stack>
  );
};

export default Footer;

import React, { useEffect } from "react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  errorNotify,
  isValidEmail,
  succesNotify,
  validatePassword,
  warningNotify,
} from "../../CommonCode/Commonfunc";
import { axiosAPI } from "../../Axios/Axios";
import { useDispatch, useSelector } from "react-redux";
import {
  Checkbox,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Button,
  ListItemButton,
  ListItemIcon,
  Stack,
  Typography,
} from "@mui/material";
import Languages from "../../CommonCode/Languages";
import InputWithLabel from "../../Components/InputWithLabel";
import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Paper from "@mui/material/Paper";
import "../../../App.css";
import "react-phone-input-2/lib/style.css";
import {profanityfn}  from "../../Profanity/Profanity";
import Group839 from "../../../Images/Group839.png";
import { doSignInWithGoogle } from "../../../AuthConfig/Auth";
import CompanyType from "../../CommonCode/CompanyType";
import PhoneInput from "react-phone-input-2";
import CustomButton from "../../Components/CustomButton";
export default function CustomEmployerSignup({ user, loginType }) {
  const redirect = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const currentDate = new Date();
  const maxDate = new Date(currentDate);
  maxDate.setFullYear(currentDate.getFullYear() - 50);

  const Labels = useSelector((state) => state.allLabels.labels);

  const [isSigningIn, setIsSigningIn] = useState(false);

  const [companyname, setcompanyname] = useState("");
  const [companyTypes, setcompanyTypes] = useState("");
  const [countryCode, setcountryCode] = useState("");
  const [contactnumber, setcontactnumber] = useState("");
  const [Language, setLanguage] = useState("");

  const onGoogleSignIn = (e) => {
    if (!isSigningIn) {
      setIsSigningIn(true);
      console.log("setIsSigningIn");
      
      doSignInWithGoogle()
        .then((res) => {
          axiosAPI
            .post("/login", {
              username: res.providerData[0].email,
              password: res.providerData[0].uid,
              userRole: "2",
              language:localStorage.getItem("language")
            })
            .then((response) => {
              const { success, message, data } = response.data;
              if (success) {
                console.log("empyuiyyit");
                
                localStorage.setItem("employerToken", data.token);
                if (data?.companyType) {
                  localStorage.setItem("employerType", data.companyType);
                }
                if (data?.userType) {
                  localStorage.setItem("userType", data.userType);
                }
                if (data?.resettedByAdmin) {
                  localStorage.setItem("resettedByAdmin", data.resettedByAdmin);
                  if (data.resettedByAdmin === 1) {
                    redirect("/emppwdchange");
                  }
                }
                if (data?.preferredLanguage) {
                  localStorage.setItem("language", data?.preferredLanguage);
                  if (data.resettedByAdmin === 0) {
                    redirect("/employerDashboard");
                    succesNotify(message);
                  }
                } else {
                  localStorage.setItem("language", "en");
                }

                setIsButtonDisabled(false);
                return data;
              } else {
                errorNotify(message)
                setIsButtonDisabled(false);

                const postdata = {
                  contactName: res.providerData[0].displayName,
                  username: res.providerData[0].email,
                  password: res.providerData[0].uid,
                  confirmPassword: res.providerData[0].uid,
                  companyName: localStorage.getItem("COMPNAMELOGIN"),
                  companyType: localStorage.getItem("COMPTYPELOGIN"),
                  countrycode: localStorage.getItem("COUNTRYCODELOGIN"),
                  contactNumber: localStorage.getItem("CONTACTNUMBERLOGIN"),
                  language: localStorage.getItem("LANGLOGIN"),
                };
                axiosAPI
                  .post("/registerEmployer", postdata)
                  .then((response) => {
                    const { success, message } = response.data;
                    if (success) {
                      // redirect("/login", { state: 1 });
                      succesNotify(Labels.accountCreated);
                      onGoogleSignIn();
                      setIsButtonDisabled(false);
                    } else {
                      console.log("roor");
                      
                      errorNotify(message);
                      setIsButtonDisabled(false);
                    }
                  })
                  .catch((res) => {
                    // warningNotify(Labels.credentialValidation);
                  });
              }
            })
            .catch((e) => {
              setIsButtonDisabled(false);
            });
        })
        .catch((err) => {
          setIsSigningIn(false);
        });
    }
  };
  const subfn = () => {
    localStorage.setItem("COMPNAMELOGIN", companyname);
    localStorage.setItem("COMPTYPELOGIN", companyTypes);
    localStorage.setItem("COUNTRYCODELOGIN", countryCode);
    localStorage.setItem("CONTACTNUMBERLOGIN", contactnumber);
    localStorage.setItem("LANGLOGIN", Language);
    setIsButtonDisabled(true);
    if (!companyname) {
      errorNotify(Labels.companyNameRequired);
      setIsButtonDisabled(false);
    } else if (companyname.length > 200) {
      errorNotify(Labels?.companyNameValidation);
      setIsButtonDisabled(false);
    } else if (!companyTypes) {
      errorNotify(Labels?.ComapnyTypeValidation);
      setIsButtonDisabled(false);
    } else if (!countryCode) {
      errorNotify(Labels?.countryCodeRequired);
      setIsButtonDisabled(false);
    } else if (!contactnumber) {
      errorNotify(Labels?.contactNumberRequired);
      setIsButtonDisabled(false);
    } else if (!/^\d*$/.test(contactnumber)) {
      errorNotify(Labels?.phoneNumberValidation);
      setIsButtonDisabled(false);
    } else if (contactnumber.length < 7) {
      errorNotify(Labels?.contactMinValidation);
      setIsButtonDisabled(false);
    } else if (contactnumber.length > 14) {
      errorNotify(Labels?.contactMaxValidation);
      setIsButtonDisabled(false);
    } else if (!Language) {
      errorNotify(Labels?.languageRequired);
      setIsButtonDisabled(false);
    } else {
    
      console.log("im gooogle")
      onGoogleSignIn();
    }
  };

  return (
    <Paper
      sx={{
        position: "relative",
        top: "60px",
        color: "#fff",
        background: "transparent",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        // backgroundImage: `url(${Group839})`,
        padding: "50px",
        boxShadow: "0",
      }}
    >
      <Typography
        variant="h3"
        sx={{
          fontWeight: 600,
          fontSize: {
            xs: "30px",
            lg: "40px",
          },
        }}
        color="inherit"
        gutterBottom
      >
        Welcome to Naranjo Jobs! We're excited to have you on board.
      </Typography>
      <Typography
        variant="h3"
        sx={{
          fontWeight: 300,
          fontSize: {
            xs: "15px",
            lg: "20px",
          },
        }}
        color="inherit"
      >
        Before you sign in using {loginType}, please fill the fields shown
        below.
      </Typography>
      <br />
      <Grid container spacing={2}>
        {/* -------------------company name------------------------ */}
        <Grid item xs={12} sm={6}>
          <InputWithLabel
            style={{ width: "100%" }}
            labelStyle={{ color: "white" }}
            InputLabel={Labels.CompanyConsultancyName}
            required
            label={Labels.CompanyConsultancyName}
            type="text"
            name="companyname"
            value={companyname}
            onChange={(e) => {
              setcompanyname(e.target.value);
            }}
          />
        </Grid>
        {/* -------------------company Type------------------------ */}
        <Grid item xs={12} sm={6}>
          <div style={{ margin: "10px 5px" }}>
            <label style={{ color: "white" }}>{Labels?.CompanyType}</label>
            <span style={{ color: "red" }}>*</span>
            <br />
            <CompanyType
              value={companyTypes}
              setValue={setcompanyTypes}
              style={{ width: "100%", background: "white" }}
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div style={{ margin: "10px 5px" }}>
            <label style={{ color: "white" }}>{Labels?.ContactNumber}</label>
            <span style={{ color: "red" }}>*</span>
            <br />
            <Stack direction="row">
              <PhoneInput
                country={"cr"}
                style={{ width: "110px", color: "black" }}
                inputProps={{ required: true, readOnly: true }}
                value={countryCode}
                onChange={(e) => {
                  setcountryCode(e);
                }}
                readOnly
              />
              <InputWithLabel
                style={{ width: "100%" }}
                label={Labels.ContactNumber}
                type="text"
                name="contactnumber"
                display="true"
                value={contactnumber}
                onChange={(e) => setcontactnumber(e.target.value)}
              />
            </Stack>
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div style={{ margin: "10px 5px" }}>
            <label style={{ color: "white" }}>{Labels?.language}</label>
            <span style={{ color: "red" }}>*</span>
            <br />
            <Languages
              style={{
                width: "100%",
                background: "white",
              }}
              selectLabel={Labels.PreferredLanguages}
              value={Language}
              setValue={setLanguage}
            />
          </div>
        </Grid>
      </Grid>
      <Stack textAlign="center">
        <CustomButton
          label={Labels?.signUp}
          style={{
            fontSize: "16px",
            lineHeight: "24px",
            color: "#FFFFFF",
            width: "30%",
            margin: "20px auto",
            border: "2px solid white",
          }}
          onClick={subfn}
          disabled={isButtonDisabled}
        />
      </Stack>
    </Paper>
  );
}

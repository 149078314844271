import React from "react";
import { Stack, Typography, Grid, Button } from "@mui/material";
import jobadvertise from "../../Images/jobadvertise.png";
import CustomButton from "../Components/CustomButton";
import { useNavigate } from "react-router-dom";
import "./Style.css";
import InputBase from "@mui/material/InputBase";
import { useSelector } from "react-redux";
import Blue_bg from "../../Images/bgImage.png";
import { Padding } from "@mui/icons-material";

const Search = () => {
  const redirect = useNavigate();
  const Labels = useSelector((state) => state.allLabels.labels);
  return (
    <Stack
      sx={{
        backgroundColor: "lightgrey", // Temporarily add a background color to check visibility
        backgroundImage: `url(${Blue_bg})`,
        backgroundSize: "cover",
        borderRadius: 5,
        width: "100%",
        marginBottom: 8,
        paddingTop: 8,
        paddingBottom: 8,
        paddingRight: 8,
        paddingLeft: { xs: 3, lg: 8 },
        color: "#fff",
      }}
    >
      <div className="advertisement">
        <Grid sx={12} lg={3}>
          <div>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: { xs: "25px", md: "35px" },
                color: "#fff",
              }}
            >
              {Labels?.advertiseyourbrand}
            </Typography>

            <Typography
              sx={{
                fontWeight: 400,
                fontSize: {
                  xs: "16px",
                  lg: "20px",
                },
                color: "#fff",
                maxWidth: {
                  xs: "350px", // Adjust this value based on the font size and your character width estimation
                  lg: "700px", // Adjust for larger screens
                },
                wordWrap: "break-word",
              }}
            >
              Lorem ipsum, or lipsum as it is sometimes known, is dummy text
              used in laying out print, graphic or web designs. 
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} lg={9}>
          <div style={{ display: "flex", alignItems: "center", gap: "16px" }}>
            {/* <div className="inputField_search">
      <InputBase
        sx={{
          flex: 1,
          backgroundColor: "white",
        borderRadius:10,
          color: "#51DEA9",
          fontStyle: "poppins",
          fontWeight: 300,
          padding:"12px 35px 8px",
          fontSize: "22px",
          lineHeight: "44px",
          ml: 1,
          "& .css-yz9k0d-MuiInputBase-input": {
           padding:"10px 35px",
          },
        }}
        name="jobsearch"
        // value={formdata.jobsearch}
        // onChange={(e) => JobSerach(e)}
        placeholder="Enter your Email"
        inputProps={{ "aria-label": "search google maps" ,}}
      />
    </div> */}
            <Button
              variant="outlined"
              style={{
                width: 160,
                height: 50,

                fontWeight: 500,
                backgroundColor: "#005F8E",
                fontSize: "16px",
                textTransform: "capitalize",
                fontFamily: "Poppins",
                borderRadius: "50px",
                color: "#fff",
                border: "1px solid #fff",
                padding: 10,
              }}
              onClick={() => redirect("/contact")}
            >
             {Labels?.CallusNow	}
            </Button>
          </div>
        </Grid>
      </div>
    </Stack>
  );
};

export default Search;
